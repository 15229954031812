.footer {
    background-color: #33333370;  /* Dark background */
    color: #fff;              /* White text */
    text-align: center;       /* Centered text */
    padding: 5px 0;         /* 10px padding top and bottom */
    margin: 10px 0 0 0;
    bottom: 0;
    width: 100%;              /* Full width */
    font-size: 14px;          /* Adjust font size as needed */
}
