#projects_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.project {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-reverse {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.__img_wrapper {
  width: 60em;
  height: 40em;
  display: flex;
  justify-content: center;
  align-items: center;

}
.__img_wrapper > img {
  height: 100%;
  width: fit-content;
}
#Parkinsons{
  height: 70%;
}
#PLR{
  height: 70%;
}
#AEDFSM{
  height: 70%;
}
.__content_wrapper {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}
.title {
  text-align: center;
  font-size: 1.5rem;
}
.description {
  font-size: 1rem;
  width: 80%;
}
@media (max-width: 1024px) {
  .project {
    flex-direction: column;
  }
  .__content_wrapper {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .__img_wrapper {
    margin: 10px 0;
    width: 60vw;
    height: auto;
  }
  .__img_wrapper > img {
    max-width: 100%;
    height: auto;
  }
  .project {
    flex-direction: column;
  }
  .__content_wrapper {
    width: 90%;
  }
}