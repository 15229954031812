nav {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  transform-origin: top left;
  transform: rotate(-90deg) translateX(-100%);
  top: 10%;
  left: 1%;
  font-size: 1.8rem;
}

nav a {
  margin-right: 40px;
  text-decoration: none;
  color: #575757;
  padding: 5px 10px;
}

nav a:hover {
  margin-right: 40px;
  text-decoration: none;
  color: #ec704c;
}

nav a.active {
  color: #ec704c;
}
